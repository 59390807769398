import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEye,
  faEyeSlash,
  faMapPin,
  faMinus,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import { admin_fee } from "../components/utils";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import { formatNumberWithout, getLink, validateEmail } from "../helper";
import CountDown from "../components/functions/CountDown";
import axios from "axios";
import LoadingScreen from "./Loading/LoadingScreen";
import { useLocation } from "react-router-dom";

const LazyImage = lazy(() => import("../components/functions/LazyImage"));

const Home = () => {
  const navigate = useNavigate();

  const [count, setCount] = useState(1);
  const [mailStatus, setMailStatus] = useState(false);
  const [csNumber, setCsNumber] = useState("6282284897840");
  const [showModal, setShowModal] = useState(false);
  const [flashSaleStt, setFlashSaleStt] = useState(true);
  const [passwordShow, setPasswordShow] = useState(true);
  const [mailAvail, setMailAvail] = useState(false);
  const [disableMail, setDisableMail] = useState(false);
  const [disableCheckout, setDisableBtnCheckout] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [listVariation, setListVariation] = useState([]);
  const [cityVal, setCityVal] = useState({});
  const [dataMaps, setDataMaps] = useState([]);
  const [cityName, setCityName] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [cityError, setCityError] = useState("Masukkan 3 huruf");

  const preventEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const handleCityInput = (e) => {
    const input = e.target.value;

    // Update city name and list
    setCityName(input);
    if (input.length >= 3) {
      checkCity(input);
    } else {
      setCityList([]);
      setCityVal({});
    }

    // Update error message or clear it
    const charsLeft = Math.max(3 - input.length, 0);
    if (charsLeft > 0) {
      setCityError(
        <>
          Masukkan <span style={{ color: "red" }}>{charsLeft}</span> huruf lagi
        </>
      );
    } else {
      setCityError("");
    }
  };

  const [variationTicket, setVariationTicket] = useState([]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const addData = (
    id,
    name,
    price,
    discount,
    allocated_person,
    real_price,
    cat_name,
    qyt,
    isAdding
  ) => {
    setVariationTicket((prevArray) => {
      const existingItem = prevArray.find((item) => item.id === id);

      if (existingItem) {
        return prevArray
          .map((item) => {
            if (item.id === id) {
              const newQyt = isAdding ? item.qyt + qyt : item.qyt - qyt;
              return {
                ...item,
                qyt: newQyt > 0 ? newQyt : 0,
              };
            }
            return item;
          })
          .filter((item) => item.qyt > 0);
      } else {
        if (isAdding) {
          const newData = {
            id: id,
            name: name,
            allocated_person: allocated_person,
            price: price,
            real_price: real_price,
            discount: discount,
            qyt: qyt,
            cat_name: cat_name,
          };

          return [...prevArray, newData];
        } else {
          return prevArray;
        }
      }
    });
  };

  const getTotalQytById = (id) => {
    // Filter data berdasarkan id yang dikirimkan
    const filteredItems = variationTicket.filter((item) => item.id === id);

    // Menghitung total qyt dari item yang difilter
    const totalQyt = filteredItems.reduce((total, item) => total + item.qyt, 0);

    return totalQyt;
  };

  const calculateTotalPrice = () => {
    return variationTicket.reduce((total, item) => {
      return total + item.real_price * item.qyt;
    }, 0);
  };

  const calculateTotalDiscTicket = () => {
    return variationTicket.reduce((total, item) => {
      return total + item.discount * item.qyt;
    }, 0);
  };

  const getTotalQyt = () => {
    const totalQyt = variationTicket.reduce(
      (total, item) => total + item.qyt,
      0
    );

    return totalQyt;
  };

  const validateForm = () => {
    const newErrors = {};

    const email = document.getElementById("email").value;
    const name = document.getElementById("name")?.value;
    const phone = document.getElementById("phone")?.value;
    const password = document.getElementById("password")?.value;

    if (!validateEmail(email)) {
      newErrors.email = "Format email salah";
      scrollToSection(section3Ref);
    }

    if (mailAvail) {
      if (cityVal.name === undefined) {
        newErrors.city = "Pilih Salah Satu Kota Terlabih Dahulu";
        scrollToSection(section3Ref);
      }

      if (!name?.trim()) {
        newErrors.name = "Nama tidak boleh kosong";
        scrollToSection(section3Ref);
      }

      if (!phone?.trim()) {
        newErrors.phone = "No. WhatsApp tidak boleh kosong";
        scrollToSection(section3Ref);
      } else if (!/^\d+$/.test(phone)) {
        newErrors.phone = "No. WhatsApp harus berupa angka";
        scrollToSection(section3Ref);
      } else if (phone.length < 10) {
        newErrors.phone = "No. WhatsApp harus minimal 10 digit";
        scrollToSection(section3Ref);
      }

      if (!password?.trim()) {
        newErrors.password = "Password tidak boleh kosong";
        scrollToSection(section3Ref);
      } else if (password.length < 6) {
        newErrors.password = "Password harus minimal 6 karakter";
        scrollToSection(section3Ref);
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNavigate = () => {
    navigate("/seat", { state: { dataMaps, variation: variationTicket } });
  };

  const handlePay = async (e, vars) => {
    e.preventDefault();
    if (mailStatus) {
      if (!validateForm()) return;
      setDisableBtnCheckout(true);
      setLoading(true);
      const params = {
        email: document.getElementById("email").value,
        kupon: "",
        city: cityVal,
        source: id ?? "meta",
      };
      if (mailAvail) {
        params.phone = document.getElementById("phone").value;
        params.name = document.getElementById("name").value;
        params.password = document.getElementById("password").value;
      } else {
        params.phone = 9999999999;
        params.name = "name";
        params.password = "password01";
      }
      if (vars !== undefined) {
        params.variation = [...variationTicket, vars];
      } else {
        params.variation = variationTicket;
      }
      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/booking_soul`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "post",
            data: JSON.stringify(params),
          }
        );
        if (response.data.status) {
          if (response.data.status) {
            setDisableBtnCheckout(true);
            setTimeout(() => {
              setLoading(false);
              window.location.href = response.data.data.redirect;
            }, 3000);
          }
        } else {
          setDisableBtnCheckout(false);
          setLoading(false);
          alert(response.data.message);
          throw new Error("Request failed");
        }
      } catch (error) {
        setDisableBtnCheckout(false);
        setLoading(false);
      }
    } else {
      alert("Masukkan Email dan Klik Lanjutkan");
    }
  };

  const getDiscount = async () => {
    try {
      const response = await axios(`${process.env.REACT_APP_API_URL}/d__soul`, {
        headers: {
          "Content-Type": "application/json",
          token: process.env.REACT_APP_API_KEY,
        },
        method: "post",
        data: "",
      });

      if (response.data.status) {
        setCsNumber(response.data.data.cs___n);
        setFlashSaleStt(response.data.data.s___m);
        setDataMaps(response.data.data.s___t);
        const e = JSON.parse(response.data.data.v___e);
        setListVariation(e);
      } else {
      }
    } catch (error) {}
  };

  const checkMail = async () => {
    const email = document.getElementById("email").value;
    const valid = validateEmail(email);
    if (valid.isValid) {
      const datas = {
        email: email,
      };

      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/check_email`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "post",
            data: JSON.stringify(datas),
          }
        );
        if (response.data.status) {
          setDisableMail(true);
          if (response.data.message === "Email Available") {
            setMailStatus(true);
            setMailAvail(false);
          } else {
            setMailStatus(true);
            setMailAvail(true);
          }
        } else {
          alert("Email not available");
        }
      } catch (error) {}
    } else {
      alert(valid.error);
    }
  };

  const checkCity = async (city) => {
    setCityVal({});
    setCityName(city);
    if (city.length >= 3) {
      const datas = {
        city: city,
      };

      try {
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/check_city`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "post",
            data: JSON.stringify(datas),
          }
        );
        if (response.data.status) {
          if (response.data.data.length > 0) {
            setCityList(response.data.data);
          } else {
            setCityList([]);
            setCityError(
              <span style={{ color: "red" }}>Kota tidak ditemukan</span>
            );
          }
        } else {
          alert("City not available");
        }
      } catch (error) {
        setCityError("Terjadi kesalahan, coba lagi");
      }
    }
  };

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        setShowModal(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (count >= 14) return;

    const interval = setInterval(() => {
      setCount((prevCount) => prevCount + 1);
    }, 100);

    return () => clearInterval(interval);
  }, [count, showModal]);

  useEffect(() => {
    if (imageLoaded) {
      setImageLoaded(true);
    }
  }, [imageLoaded]);

  useEffect(() => {
    getDiscount();
  }, []);

  return (
    <Container className="home text-white" fluid>
      {!imageLoaded && <LoadingScreen />}

      {flashSaleStt && (
        <Col
          xs={6}
          className="btn-flash-sale d-flex justify-content-center align-items-center"
        >
          <Col className="text-center d-flex justify-content-center align-items-center">
            <Image
              src={require("../images/soul/fire.png")}
              style={{ width: "1.5em", marginTop: "-10px", marginRight: "5px" }}
            />
            <h5>FLASH SALE!</h5>
          </Col>
          <Col className="text-center">
            <CountDown h={3} m={0} s={0} />
          </Col>
        </Col>
      )}
      {/* <Button
        className="btn-flash-sale"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${csNumber}&text=Halo+Admin+Ekspectanica%2C+Saya+ingin+bertanya+tentang+Ekspectanica&type=phone_number&app_absent=0`
          )
        }
      /> */}
      <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${csNumber}&text=Halo+Admin+Soul%2C+Saya+ingin+bertanya+tentang+Soul&type=phone_number&app_absent=0`
          )
        }
      />

      <AnimationTitles size="32px" weight="700">
        <motion.span style={{ fontWeight: "Bold" }}>
          <Col className="text-center">
            <Image
              className="img-hero"
              src={require("../images/soul/soul-logo.png")}
              loading="lazy"
            />
          </Col>
        </motion.span>
      </AnimationTitles>
      <Col className="text-center">
        <AnimationTitles>
          <motion.span>
            <h6
              style={{
                fontSize: "0.9rem",
                fontWeight: "400",
                color: "rgba(255, 227, 120, 1)",
              }}
            >
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "5px" }}
              />
              SATURDAY, SEPTEMBER 14th 2024
            </h6>
            <h6
              style={{ fontSize: "0.9rem", fontWeight: "400" }}
              className="mb-3"
            >
              <FontAwesomeIcon icon={faMapPin} style={{ marginRight: "5px" }} />
              THE KASABLANKA HALL, SOUTH JAKARTA
            </h6>
            <Image
              className="img-icon-buy-now"
              src={require("../images/soul/guest-start-tx.png")}
              loading="lazy"
            />
          </motion.span>
        </AnimationTitles>
      </Col>
      <Col xs={4} className="text-end">
        <AnimationTitles>
          <motion.span>
            <Suspense fallback={<div></div>}>
              <LazyImage
                className="img-icon-bersama"
                src={require("../images/soul/soul-artis.png")}
                onLoad={handleImageLoad}
              />
            </Suspense>
          </motion.span>
        </AnimationTitles>
      </Col>
      <AnimationTitles>
        <motion.span>
          <Col className="text-center" ref={section2Ref}>
            <Button
              className="btn-buy-now"
              role="button"
              onClick={() => handleNavigate()}
            >
              GET YOURS NOW!
            </Button>
            <br />
            <br />
            <Image
              className="img-icon-buy-now mb-4"
              src={require("../images/soul/seating-tx.png")}
              loading="lazy"
            />

            <Image
              className="img-seating-plan mb-4"
              src={require("../images/soul/seating-plan.png")}
              loading="lazy"
            />
            <Image
              className="img-icon-buy-now"
              src={require("../images/soul/buy-tickets.png")}
              loading="lazy"
            />
          </Col>
        </motion.span>
      </AnimationTitles>
      <Col className="ticket-box">
        {listVariation.map((item, index) => {
          return (
            <Row className="d-flex mb-2 justify-content-start" key={index}>
              <Col
                xs={8}
                style={{ height: "2rem" }}
                className="align-items-center justify-content-start d-flex text-start"
              >
                <div
                  style={{
                    backgroundColor: item.color,
                    height: "1rem",
                    marginTop: "-5px",
                    marginRight: "10px",
                    width: "1rem",
                  }}
                />
                <h6 style={{ fontWeight: "800", marginRight: "20px" }}>
                  {item.cat_name}
                  {" : "}
                </h6>

                {item.real_price === 0 ? (
                  <h6 style={{ color: "red", fontWeight: "800" }}>Sold Out</h6>
                ) : (
                  <h6>Rp {formatNumberWithout(item.real_price)}</h6>
                )}
              </Col>
              <Col
                xs={4}
                className="d-flex justify-content-center px-2"
                style={{ height: "2rem" }}
              >
                {item.real_price === 0 ? (
                  ""
                ) : (
                  <Row
                    xs={12}
                    className="day-2-active w-100 align-items-center justify-content-between"
                    style={{ cursor: "pointer" }}
                  >
                    <Col
                      xs={3}
                      className="bg-ticket-tot"
                      onClick={() =>
                        addData(
                          item.id,
                          item.name,
                          item.price,
                          item.discount,
                          item.allocated_person,
                          item.real_price,
                          item.cat_name,
                          1,
                          false
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Col>
                    <Col
                      xs={3}
                      className="bg-ticket-txtot justify-content-end align-items-end d-flex-col"
                    >
                      <h6 className="bg-ticket-txtot">
                        {getTotalQytById(item.id)}
                      </h6>
                    </Col>
                    <Col
                      xs={3}
                      className="bg-ticket-tot"
                      onClick={() =>
                        getTotalQytById(item.id) <= 9 &&
                        addData(
                          item.id,
                          item.name,
                          item.price,
                          item.discount,
                          item.allocated_person,
                          item.real_price,
                          item.cat_name,
                          1,
                          true
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          );
        })}

        <Row className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3">
          <Col xs={1} className="lines" />
          <Col xs={10} className="text-center" style={{ fontSize: "0.8rem" }}>
            Email Pemesan
          </Col>
          <Col xs={1} className="lines" />
        </Row>
        <Form onSubmit={handlePay}>
          <InputGroup className="mb-1">
            <Form.Control
              type="email"
              id="email"
              className="input-email"
              placeholder="Masukkan e-mail"
              disabled={disableMail}
              required
              onKeyDown={preventEnter}
            />
            <Button
              className="btn-email_ver"
              onClick={() =>
                disableMail
                  ? [
                      setDisableMail(false),
                      (document.getElementById("email").value = ""),
                      setMailStatus(false),
                      setMailAvail(false),
                    ]
                  : checkMail()
              }
            >
              {disableMail ? "Ganti Email" : "Lanjutkan"}
            </Button>
          </InputGroup>
          {mailStatus && (
            <h6 className="text-warning">Email Berhasil Divalidasi !</h6>
          )}
          <Col>
            {mailAvail && (
              <>
                <Row
                  className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3"
                  ref={section3Ref}
                >
                  <Col xs={1} className="lines" />
                  <Col
                    xs={10}
                    className="text-center "
                    style={{ fontSize: "0.8rem" }}
                  >
                    Buat Akun
                    <Image
                      src={require("../images/mobile/pocketsid.webp")}
                      alt="Pocketsid Logo"
                      style={{
                        width: "auto",
                        height: "13px",
                        marginLeft: "10px",
                        marginTop: "-0.2rem",
                      }}
                      loading="lazy"
                    />
                  </Col>
                  <Col xs={1} className="lines" />
                </Row>
                <Form.Label>Nama</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  className="input-regis"
                  placeholder="Masukkan Nama"
                  isInvalid={!!errors.name}
                  required
                  onKeyDown={preventEnter}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>

                <Form.Label>No. WhatsApp</Form.Label>
                <Form.Control
                  type="number"
                  id="phone"
                  className="input-regis"
                  placeholder="Masukkan No. WhatsApp"
                  isInvalid={!!errors.phone}
                  required
                  onKeyDown={preventEnter}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>

                <Form.Label>Kota Asal</Form.Label>
                <Form.Text className="text-muted" style={{ marginLeft: "5px" }}>
                  {cityError}
                </Form.Text>
                <InputGroup className="">
                  <Form.Control
                    type="text"
                    id="city"
                    className="input-city"
                    onChange={handleCityInput}
                    value={cityName}
                    isInvalid={!!errors.city}
                    placeholder="Cari Nama Kota"
                    required
                    onKeyDown={(e) => {
                      preventEnter(e);
                      if (/[\d]/.test(e.key)) {
                        e.preventDefault(); // Prevents entering numbers
                      }
                    }}
                    onBlur={() => {
                      if (cityList.length === 0 || !cityVal.name) {
                        setErrors({ ...errors, city: "Pilih Kota" });
                      }
                    }}
                  />
                  <Button className="btn-city_ver">
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>

                {cityList.length > 0 && (
                  <Col
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "white",
                      marginBottom: "10px",
                    }}
                  >
                    <Col
                      style={{
                        borderRadius: "30px",
                        color: "black",
                        fontWeight: "700",
                        backgroundColor: "white",
                        paddingBlock: "10px",
                        paddingInline: "10px",
                      }}
                    >
                      Pilih Kota
                    </Col>
                    {cityList?.map((item, index) => {
                      const isLast = index === cityList.length - 1;
                      return (
                        <React.Fragment key={item.id || item.name || index}>
                          <Col
                            onClick={() => [
                              setCityName(item.name),
                              setCityList([]),
                              setCityVal(item),
                              setCityError(""),
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                city: undefined,
                              })),
                            ]}
                            key={index}
                            style={{
                              borderBottom: "1px black",
                              borderRadius: "20px",
                              color: "black",
                              backgroundColor: "white",
                              paddingBlock: "10px",
                              paddingInline: "10px",
                            }}
                          >
                            {item.name}
                          </Col>
                          {!isLast && <Col className="lines-black" />}
                        </React.Fragment>
                      );
                    })}
                  </Col>
                )}

                <Form.Label> Buat Password</Form.Label>
                <InputGroup className="">
                  <Form.Control
                    type={passwordShow ? "password" : "text"}
                    id="password"
                    className="input-regis"
                    placeholder="Buat Password"
                    isInvalid={!!errors.password}
                    required
                    onKeyDown={preventEnter}
                  />
                  <Button
                    className="btn-city_ver"
                    onClick={() => setPasswordShow(!passwordShow)}
                  >
                    <FontAwesomeIcon
                      icon={!passwordShow ? faEye : faEyeSlash}
                    />
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
                <Row className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3">
                  <Col xs={1} className="lines" />
                  <Col
                    xs={10}
                    className="text-center "
                    style={{ fontSize: "0.8rem" }}
                  >
                    Kamu Bisa Gunakan Email dan Password tersebut untuk login ke
                    pockets.id
                  </Col>
                  <Col xs={1} className="lines" />
                </Row>
              </>
            )}
            <Image
              src={require("../images/soul/payment-summ.png")}
              loading="lazy"
              className="img-icon-buy-now-pay"
            />
            <Row>
              <Col xs={6} className="text-start">
                Tiket ({getTotalQyt()}x)
              </Col>
              <Col xs={6} className="text-end">
                {formatNumberWithout(calculateTotalPrice())}
              </Col>
            </Row>
            <Row>
              <Col xs={8} className="text-start">
                Diskon Untuk Kamu{" "}
              </Col>
              <Col xs={4} className="text-end tx-discount">
                -{formatNumberWithout(calculateTotalDiscTicket())}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="text-start">
                Admin Fee{" "}
                <span
                  style={{ color: "rgb(32, 120, 35)", fontWeight: 800 }}
                ></span>
              </Col>
              <Col xs={6} className="text-end">
                {formatNumberWithout(admin_fee)}
              </Col>
            </Row>
            <hr />
            <Row className="mb-4">
              <Col xs={6} className="text-start tx-total-ticket">
                Hanya bayar
              </Col>
              <Col xs={6} className="text-end tx-total-ticket">
                {formatNumberWithout(calculateTotalPrice() + admin_fee)}
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-item-center mt-4 px-3 mb-3">
              <Col xs={1} className="lines" />
              <Col
                xs={10}
                className="text-center "
                style={{ fontSize: "0.8rem" }}
              >
                Dapatkan Eticket di emailmu atau akun pockets.id kamu
              </Col>
              <Col xs={1} className="lines" />
            </Row>
            <Row>
              <Col xs={12} className="text-end" style={{ marginTop: "14px" }}>
                <Button
                  className="btn-buy-now-ticket text-white"
                  role="button"
                  type="submit"
                  disabled={disableCheckout}
                >
                  <p className="p-discount-ticket text-white">
                    Kamu hemat{" "}
                    <span style={{ fontWeight: "800" }}>
                      {formatNumberWithout(calculateTotalDiscTicket())}
                    </span>
                  </p>
                  <br />
                  PESAN SEKARANG
                </Button>
              </Col>
            </Row>
          </Col>
        </Form>
        {loading && (
          <div className="loader-overlay">
            <Spinner animation="border" variant="light" />
          </div>
        )}
        <h6 className="text-center mt-5">Supported by Pocketsid © 2024</h6>
      </Col>
    </Container>
  );
};

export default Home;
