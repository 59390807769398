// App.js
import React, { useEffect, useState } from "react";
import SeatMap from "./SeatMap";
import { Col, Container, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Seating = () => {
  const location = useLocation();
  const variation = location?.state?.variation || [];
  const datas = location?.state?.dataMaps;

  const [saveSeatAll, setSaveSeatAll] = useState([]);

  const handleSeatClick = (seat) => {
    setSaveSeatAll((prevArray) => {
      const seatData = seat.map((item) => ({
        id: item.id,
        ticket_id: item.ticket_id,
        name: item.name,
        x: item.x,
        y: item.y,
        loc2stage: item.loc2stage,
        status: item.status,
        seat_charged: item.seat_charged,
        due_date: item.due_date,
      }));

      // Gabungkan dan hilangkan duplikat berdasarkan id
      const combinedArray = [...prevArray, ...seatData];
      const uniqueArray = combinedArray.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );

      return uniqueArray;
    });
  };

  useEffect(() => {
    console.log(saveSeatAll);
  }, [saveSeatAll]);

  return (
    <Container className="seating" fluid>
      <Col>
        <Tabs
          style={{
            backgroundColor: "#AF904A",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
          defaultActiveKey="Cat 1"
          id="noanim-tab-example"
          className="custom-tabs"
        >
          {variation.map((item, i) => {
            return (
              <Tab
                eventKey={item.cat_name}
                key={i}
                title={<span>{item.cat_name}</span>}
              >
                <Col>
                  <SeatMap
                    seatData={datas}
                    filter={item.id}
                    handleSaveSeatAll={handleSeatClick}
                    type="cat-1"
                    maxSelect={item?.qyt * item?.allocated_person}
                  />
                </Col>
              </Tab>
            );
          })}
        </Tabs>
      </Col>
    </Container>
  );
};

export default Seating;
