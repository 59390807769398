import React, { useState, useEffect, useRef } from "react";
import ControlButtons from "./ControlButtons";

const Seat = ({ seatData, filter, maxSelect }) => {
  const filteredSeats = seatData.filter(
    (seat) =>
      seat.ticket_id === filter ||
      seat.ticket_id === 9999 ||
      seat.ticket_id === 8888
  );

  const [selectSeat, setSelectSeat] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [touchStartDistance, setTouchStartDistance] = useState(null);

  const containerRef = useRef(null);
  const seatSize = 50;
  const seatPadding = 10;
  const totalSize = seatSize + seatPadding * 2;

  const rows =
    Math.max(...seatData.map((seat) => seat.x.charCodeAt(0))) - 65 + 1;
  const cols = Math.max(...seatData.map((seat) => seat.y));
  const gridWidth = cols * totalSize;
  const gridHeight = rows * totalSize;

  const [transform, setTransform] = useState({
    scale: 1,
    translateX: 0,
    translateY: 0,
  });

  const isEventInContainer = (clientX, clientY) => {
    if (!containerRef.current) return false;
    const rect = containerRef.current.getBoundingClientRect();
    return (
      clientX >= rect.left &&
      clientX <= rect.right &&
      clientY >= rect.top &&
      clientY <= rect.bottom
    );
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    if (!isEventInContainer(e.touches[0].clientX, e.touches[0].clientY)) return;

    if (e.touches.length === 1) {
      setDragging(true);
      setDragStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    } else if (e.touches.length === 2) {
      const distance = getDistance(e.touches[0], e.touches[1]);
      setTouchStartDistance(distance);
    }
  };

  const handleTouchEnd = (e) => {
    e.preventDefault();
    setDragging(false);
    setTouchStartDistance(null);
  };

  const getDistance = (touch1, touch2) => {
    const dx = touch2.clientX - touch1.clientX;
    const dy = touch2.clientY - touch1.clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  useEffect(() => {
    const container = containerRef.current;

    const handleWheel = (e) => {
      if (!isEventInContainer(e.clientX, e.clientY)) return;

      e.preventDefault();
      e.stopPropagation();

      const zoomIntensity = 0.1;
      const scaleChange = e.deltaY < 0 ? 1 + zoomIntensity : 1 - zoomIntensity;

      setTransform((prev) => {
        const newScale = Math.max(0.5, Math.min(prev.scale * scaleChange, 10));
        const rect = container.getBoundingClientRect();

        const midX = e.clientX - rect.left;
        const midY = e.clientY - rect.top;

        const focusX = (midX - prev.translateX) / prev.scale;
        const focusY = (midY - prev.translateY) / prev.scale;

        const newTranslateX = midX - focusX * newScale;
        const newTranslateY = midY - focusY * newScale;

        return {
          scale: newScale,
          translateX: newTranslateX,
          translateY: newTranslateY,
        };
      });
    };

    const handleTouchMove = (e) => {
      e.preventDefault();

      if (e.touches.length === 1 && dragging) {
        const slowFactor = 3; // Faktor pengurangan kecepatan drag

        const deltaX = (e.touches[0].clientX - dragStart.x) / slowFactor;
        const deltaY = (e.touches[0].clientY - dragStart.y) / slowFactor;

        setTransform((prev) => ({
          ...prev,
          translateX: prev.translateX + deltaX,
          translateY: prev.translateY + deltaY,
        }));

        setDragStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
      } else if (e.touches.length === 2 && touchStartDistance) {
        const distance = getDistance(e.touches[0], e.touches[1]);
        const scaleChange = distance / touchStartDistance;

        setTransform((prev) => {
          const newScale = Math.max(
            0.5,
            Math.min(prev.scale * scaleChange, 10)
          );
          const rect = container.getBoundingClientRect();

          const midX =
            (e.touches[0].clientX + e.touches[1].clientX) / 2 - rect.left;
          const midY =
            (e.touches[0].clientY + e.touches[1].clientY) / 2 - rect.top;

          const focusX = (midX - prev.translateX) / prev.scale;
          const focusY = (midY - prev.translateY) / prev.scale;

          const newTranslateX = midX - focusX * newScale;
          const newTranslateY = midY - focusY * newScale;

          return {
            scale: newScale,
            translateX: newTranslateX,
            translateY: newTranslateY,
          };
        });

        setTouchStartDistance(distance);
      }
    };

    if (container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
      container.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
        container.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [transform, dragging, dragStart, touchStartDistance]);

  const handleMouseDown = (e) => {
    if (!isEventInContainer(e.clientX, e.clientY)) return;
    e.preventDefault();

    if (
      e.target.tagName === "svg" ||
      e.target.tagName === "rect" ||
      e.target.tagName === "text"
    ) {
      setDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    if (!isEventInContainer(e.clientX, e.clientY)) return;
    e.preventDefault();

    const deltaX = e.clientX - dragStart.x;
    const deltaY = e.clientY - dragStart.y;
    setTransform((prev) => ({
      ...prev,
      translateX: prev.translateX + deltaX,
      translateY: prev.translateY + deltaY,
    }));
    setDragStart({ x: e.clientX, y: e.clientY });
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const colorSeat = (item) => {
    if (item.ticket_id === 8888) return "#00376bcc";
    if (item.status === "1" && checkSeatSelect(item.id)) return "#6acfcf";
    if (item.status === "1" && item.seat_charged !== null) return "#f6d16b";
    if (item.status === "1" && item.seat_charged === null) return "white";
    if (item.status === "3") return "grey";
    if (item.status === "2") return "grey";
    if (item.status === "0") return "grey";
  };

  const getRowIndex = (row) => {
    let index = 0;
    for (let i = 0; i < row.length; i++) {
      index = index * 26 + (row.charCodeAt(i) - 65 + 1);
    }
    return index - 1; // Kurangi 1 agar indeks dimulai dari 0
  };

  const handleZoomIn = () => {
    setTransform((prev) => ({
      ...prev,
      scale: Math.min(prev.scale + 0.1, 10), // Maksimum zoom 10x
    }));
  };

  const handleZoomOut = () => {
    setTransform((prev) => ({
      ...prev,
      scale: Math.max(prev.scale - 0.1, 0.5), // Minimum zoom 0.5x
    }));
  };

  const handleReset = () => {
    setTransform({
      scale: 1,
      translateX: 0,
      translateY: 0,
    });
  };

  const handleMove = (direction) => {
    const moveStep = 50; // Jumlah piksel per gerakan
    setTransform((prev) => {
      switch (direction) {
        case "up":
          return { ...prev, translateY: prev.translateY - moveStep };
        case "down":
          return { ...prev, translateY: prev.translateY + moveStep };
        case "left":
          return { ...prev, translateX: prev.translateX - moveStep };
        case "right":
          return { ...prev, translateX: prev.translateX + moveStep };
        default:
          return prev;
      }
    });
  };

  const handleSeatClick = (seat) => {
    setSelectSeat((prevArray) => {
      // Periksa apakah kursi sudah ada di array
      const seatExists = prevArray.some((item) => item.id === seat.id);

      if (!seatExists) {
        if (selectSeat.length < maxSelect) {
          return [...prevArray, seat]; // Tambahkan kursi jika belum ada
        } else {
          return [...prevArray.slice(1), seat];
        }
      } else {
        return prevArray.filter((item) => item.id !== seat.id);
      }
    });
  };

  // useEffect(() => {
  //   handleSaveSeatAll(selectSeat);
  // }, [selectSeat]);

  const checkSeatSelect = (id) => {
    const seatExists = selectSeat.some((item) => item.id === id);
    if (!seatExists) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        justifyItems: "center",
        backgroundColor: "#F4F4F466",
        paddingBlock: "10px",
      }}
    >
      <div
        ref={containerRef}
        style={{
          width: "100%",
          borderRight: "1px solid #ccc",
          borderLeft: "1px solid #ccc",
          overflow: "hidden",
          position: "relative",
          cursor: "default",
          touchAction: "none",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        // onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${gridWidth} ${gridHeight + 100}`} // Tambahkan 100 untuk ruang ekstra di bawah
          preserveAspectRatio="xMidYMid"
          style={{
            width: "100%",
            height: `${390}px`,
            transform: `translate(${transform.translateX}px, ${transform.translateY}px) scale(${transform.scale})`,
            transformOrigin: "0 0",
            display: "block",
          }}
        >
          <rect
            x={(gridWidth - 3130) / 2} // Posisi horizontal tengah
            y="0" // Pastikan berada di bagian paling atas
            width="3130"
            height="80"
            fill="#00376bcc"
            stroke="black"
            strokeWidth="2"
          />
          <text
            x={gridWidth / 2} // Tengah horizontal
            y="45" // Atur vertikal agar berada di tengah rect
            textAnchor="middle"
            fill="white"
            fontSize="32"
            fontWeight="bold"
          >
            STAGE
          </text>
          {filteredSeats.map((seat) => (
            <g
              onClick={() => handleSeatClick(seat)}
              style={{ pointerEvents: "all" }}
              onTouchStart={(e) => {
                e.preventDefault(); // Mencegah event default yang bisa mengganggu
                handleSeatClick(seat);
              }}
            >
              {seat.ticket_id !== 9999 && (
                <>
                  <rect
                    x={(seat.y - 1) * totalSize + seatPadding}
                    y={getRowIndex(seat.x) * totalSize + seatPadding + 100}
                    width={seatSize}
                    height={seatSize}
                    stroke={seat.ticket_id !== 8888 ? "black" : "#00376bcc"}
                    fill={colorSeat(seat)}
                    style={
                      seat.status === "1"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                  />

                  <text
                    x={(seat.y - 1) * totalSize + seatPadding + seatSize / 2}
                    y={
                      getRowIndex(seat.x) * totalSize +
                      seatPadding +
                      100 +
                      seatSize / 2 +
                      5
                    }
                    textAnchor="middle"
                    fill="black"
                    fontSize="14px"
                    fontWeight="bold"
                    style={
                      seat.status === "1"
                        ? { cursor: "pointer" }
                        : { cursor: "not-allowed" }
                    }
                  >
                    {seat.ticket_id !== 8888 && seat.name}
                  </text>
                </>
              )}
            </g>
          ))}
        </svg>
      </div>
      <ControlButtons
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onReset={handleReset}
        onMove={handleMove}
      />
    </div>
  );
};

export default Seat;
