import React from "react";

const ControlButtons = ({ onZoomIn, onZoomOut, onReset, onMove }) => {
  return (
    <div className="control-buttons">
      <button onClick={() => onMove("up")} className="control-button">
        <i class="fas fa-arrow-up"></i>
      </button>
      <button onClick={() => onMove("down")} className="control-button">
        <i class="fas fa-arrow-down"></i>
      </button>
      <button onClick={() => onMove("left")} className="control-button">
        <i class="fas fa-arrow-left"></i>
      </button>
      <button onClick={() => onMove("right")} className="control-button">
        <i class="fas fa-arrow-right"></i>
      </button>
      <button onClick={onZoomIn} className="control-button">
        <i class="fas fa-plus"></i>
      </button>
      <button onClick={onZoomOut} className="control-button">
        <i class="fas fa-minus"></i>
      </button>
      <button onClick={onReset} className="control-button">
        <i class="fas fa-undo-alt"></i>
      </button>
    </div>
  );
};

export default ControlButtons;
